.content-inner-edit {
    width: 882px;
    padding: 0;
    box-sizing: border-box;
    max-width: calc(100% - 17px);
}

.addnewuser-btn-addnewuser-parent,
.content-inner-edit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.addnewuser-btn-addnewuser-parent {
    align-self: stretch;
    gap: 25px;
    max-width: 100%;
    flex-shrink: 0;
}

.addnewuser-btn-addnewuser {
    align-self: stretch;
    border-radius: var(--br-10xs);
    background-color: var(--neutral-white);
    border: 1px solid var(--black-300);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--padding-xl) 0 0;
    gap: var(--gap-xl);
    font-size: var(--body-16px-bold-size);
    color: var(--trustegrity);
    font-family: var(--small-12px-medium);
}

.component-749-parent {
    width: 279px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.component-749 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-xl);
}

.wrapper-rectangle-23 {
    position: relative;
    border-radius: 116px;
    width: 94px;
    height: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.wrapper-rectangle-23-child {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 2px;
    transform: scale(1.085);
    border-radius: 50%;
}

.frame-component-grid {
    flex: 1;
    padding: var(--padding-xl) 0;
    gap: 7px;
}

.frame-component-grid,
.frame-frame1,
.frame-n-a-m-e-frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.frame-line-title-component {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-3xs);
}

.title {
    position: relative;
    font-weight: 600;
}

.component-750-icon {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}

.component-750-icon-disable {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.frame-frame1 {
    gap: var(--gap-9xs);
    font-size: var(--small-12px-medium-size);
    color: var(--black-500-gray);
}

.title1 {
    position: relative;
}

.frame-n-a-m-e-frame {
    gap: var(--gap-9xs);
}

.title2 {
    white-space: pre-wrap;
}

.title2,
.title3 {
    position: relative;
    font-weight: 500;
}

.frame-component-n-a-m-e {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 46px;
    font-size: var(--small-12px-medium-size);
    color: var(--black-500-gray);
}

.cta1-edit,
.title4 {
    position: relative;
    font-weight: 500;
}

.cta1-edit {
    font-size: var(--small-12px-medium-size);
    line-height: 21px;
    font-family: var(--small-12px-medium);
    color: var(--neutral-white);
    text-align: left;
}

.jan-3-2024 {
    color: var(--neutral-blue);
}

.btnadd-new-userbtnadd-new-u {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 0 var(--padding-12xs);
}

.frame-frame-frame {
    height: 33px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0;
    box-sizing: border-box;
}

.add-new-user-hover-edit {
    cursor: pointer;
    border: 1px solid var(--trustegrity);
    padding: var(--padding-7xs) var(--padding-6xl) var(--padding-6xs) 27px;
    background-color: var(--trustegrity);
    border-radius: var(--br-11xs);
    box-sizing: border-box;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    mix-blend-mode: normal;
    white-space: nowrap;
}

.add-new-user-hover2:hover,
.add-new-user-hover-edit:hover {
    background-color: var(--color-steelblue);
    border: 1px solid var(--color-steelblue);
    box-sizing: border-box;
}

.component-f-r-a-m-e {
    align-self: stretch;
    border-radius: var(--br-10xs);
    background-color: var(--neutral-white);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    border: 1px solid var(--black-300);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-xl) var(--padding-lgi);
    max-width: 100%;
}

.frame-l-i-n-e-title-component {
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-xl);
}

.component-700,
.frame-l-i-n-e-title-component {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
}

.component-693 {
    width: 212px;
    align-items: flex-start;
    padding: var(--padding-12xs) 0 0;
    box-sizing: border-box;
    gap: var(--gap-7xs);
}

.component-693,
.frame-frame-l-i-n-e-title-comp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.frame-n-a-m-e {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.name {
    position: relative;
    letter-spacing: 0.5px;
    line-height: 120%;
    text-transform: uppercase;
    opacity: 0.7;
    text-align: left;
    color: var(--neutral-blue);
    font-family: var(--font-sf-pro-display);
    font-size: var(--small-12px-medium-size);
}

.frame-frame-l-i-n-e-title-comp {
    align-self: stretch;
    align-items: flex-end;
    gap: var(--gap-11xs);
    font-size: var(--font-size-5xs);
    color: transparent;
}

.column-two {
    grid-column: span 2 !important;
}

.frame-f-r-a-m-e {
    align-self: stretch;
    height: 28px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-9xl) 0 0;
    box-sizing: border-box;
    position: relative;
    gap: var(--gap-11xs);
}

.line-title-component {
    align-self: stretch;
    position: relative;
    border-top: 1px solid var(--black-300);
    box-sizing: border-box;
    height: 1px;
}

.line-textarea {
    border-bottom: 1px solid var(--black-300) !important;
}

.textarea-height {
    height: 94px;
}

.component-690-icon,
.title5 {
    position: absolute;
    margin: 0 !important;
}

.title5 {
    width: calc(100% - 0px);
    border: 0;
    outline: 0;
    font-weight: 500;
    font-family: var(--small-12px-medium);
    font-size: var(--font-size-sm);
    background-color: transparent;
    top: 5px;
    left: 0;
    color: var(--trustegrity);
    text-align: left;
    display: inline-block;
    padding: 0px 25px 0px 0px;
}

.component-690-icon {
    top: 2px;
    left: 188px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}

.name1 {
    position: relative;
    letter-spacing: 0.5px;
    line-height: 120%;
    text-transform: uppercase;
    font-weight: 500;
    opacity: 0.7;
    font-size: 8px;
    font-family: var(--font-sf-pro-display);
    text-align: right;
    color: var(--trustegrity);
}

.component-697,
.frame-parent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.component-697 {
    width: 212px;
    align-items: flex-start;
    padding: var(--padding-12xs) 0 0;
    box-sizing: border-box;
    gap: var(--gap-7xs);
}

.name-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.component-690-icon2 {
    top: 2px;
    right: 0;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    cursor: pointer;
}

.eye-disable {
    top: 2px;
    right: 0;
    width: 22px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    cursor: pointer;
}

.component-690-icon2,
.eye-disable,
.title5 {
    position: absolute;
    margin: 0 !important;
}

.component-700,
.frame-l-i-n-e-title-component {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
}

.component-700 {
    flex-direction: column;
    padding: var(--padding-12xs) 0 0;
    box-sizing: border-box;
    gap: var(--gap-7xs);
    min-width: 211px;
}

.frame-frame2 {
    align-self: stretch;
    border-radius: var(--br-10xs);
    background-color: var(--neutral-white);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    border: 1px solid var(--black-300);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-xl) var(--padding-lgi);
    gap: var(--gap-11xl);
}

.frame-f-r-a-m-e-component {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-xl);
}

.component-710 {
    /* width: 212px; */
    flex: 1;
    height: 61px;
    align-items: flex-start;
    padding: var(--padding-12xs) 0 0;
    box-sizing: border-box;
    gap: var(--gap-7xs);
}

.component-710,
.component-f-r-a-m-e1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.frame-n-a-m-e-component {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.component-f-r-a-m-e1 {
    align-self: stretch;
    flex: 1;
    align-items: flex-end;
    gap: var(--gap-11xs);
    font-size: var(--font-size-sm);
    color: var(--trustegrity);
    font-family: var(--small-12px-medium);
}

.line-container {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px 0 0;
    position: relative;
    gap: var(--gap-7xs);
}

.line-div {
    align-self: stretch;
    position: relative;
    border-top: 1px solid var(--black-300);
    box-sizing: border-box;
    height: 1px;
}

.component-711,
.frame-parent1-edit {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.component-711 {
    flex: 1;
    align-items: flex-start;
    padding: var(--padding-12xs) 0 0;
    box-sizing: border-box;
    gap: var(--gap-7xs);
    min-width: 174px;
}

.name-frame {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.frame-parent1-edit {
    align-self: stretch;
    align-items: flex-end;
    gap: var(--gap-11xs);
    font-size: var(--font-size-sm);
    color: var(--trustegrity);
    font-family: var(--small-12px-medium);
}

.line-parent1 {
    align-self: stretch;
    height: 28px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-9xl) 0 0;
    box-sizing: border-box;
    position: relative;
    gap: var(--gap-3xs);
}

.frame-child1 {
    align-self: stretch;
    position: relative;
    border-top: 1px solid var(--black-300);
    box-sizing: border-box;
    height: 1px;
}

.frame-master,
.scrollbar-vertical {
    align-self: stretch;
}

.frame-master {
    border-radius: var(--br-10xs);
    background-color: var(--neutral-white);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    border: 1px solid var(--black-300);
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    padding: var(--padding-xl) var(--padding-lgi);
    gap: var(--gap-11xl);
}

.component-746-parent,
.scrollbar-vertical {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
}

.component-746-parent {
    width: 570px;
    flex-wrap: wrap;
    gap: var(--gap-xl);
}

.frame-child-a {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-xl);
    min-height: 152px;
}

.component-742,
.frame-child-a {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.frame-master,
.scrollbar-vertical {
    align-self: stretch;
}

.component-726 {
    width: 250px;
    align-items: flex-start;
    padding: var(--padding-12xs) 0 0;
    box-sizing: border-box;
    gap: var(--gap-7xs);
}

.component-726,
.frame-parent8-edit {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.frame-parent8-edit {
    align-self: stretch;
    align-items: flex-end;
    gap: var(--gap-11xs);
    font-size: var(--font-size-sm);
    color: var(--trustegrity);
    font-family: var(--small-12px-medium);
}

.component-690-icon10,
.title16 {
    position: absolute;
    margin: 0 !important;
}

.title16 {
    top: 5px;
    left: 0;
    font-weight: 500;
}

.component-690-icon10 {
    top: 2px;
    left: 226px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}

.component-746 {
    width: 249px;
    align-items: flex-start;
    padding: var(--padding-12xs) 0 0;
    box-sizing: border-box;
    gap: var(--gap-7xs);
}

.component-732 {
    width: 249px;
    align-items: flex-start;
    padding: var(--padding-12xs) 0 0;
    box-sizing: border-box;
    gap: var(--gap-7xs);
}

.component-732,
.component-746,
.frame-parent9-edit {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.add-new-user-hover1 {
    position: absolute;
    top: 18px;
    left: 899px;
    border-radius: var(--br-11xs);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-7xs) var(--padding-6xl) var(--padding-6xs) 24px;
    mix-blend-mode: normal;
    color: var(--trustegrity);
}

.add-new-user-hover1,
.cta4 {
    font-size: var(--small-12px-medium-size);
}

.cta3 {
    position: relative;
    line-height: 21px;
    font-weight: 500;
}

.btnadd-new-userbtnadd-new-u1 {
    position: absolute;
    top: 18px;
    left: 996px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 0 var(--padding-12xs);
}

.frame-button-addnewuserframe {
    width: 107px;
    height: 33px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0;
    box-sizing: border-box;
}

.add-new-user-hover2 {
    cursor: pointer;
    border: 1px solid var(--trustegrity);
    padding: var(--padding-7xs) 8px var(--padding-6xs) var(--padding-xs);
    background-color: var(--trustegrity);
    align-self: stretch;
    border-radius: var(--br-11xs);
    box-sizing: border-box;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    mix-blend-mode: normal;
    white-space: nowrap;
}

.cta4 {
    position: relative;
    line-height: 21px;
    font-weight: 500;
    font-family: var(--small-12px-medium);
    color: var(--neutral-white);
    text-align: left;
}

.profile-avtar:after {
    content: '';
    background: #00000080;
    position: absolute;
    inset: 0;
    border-radius: 50%;
    transform: scale(1.085);
    opacity: 0;
}

.profile-avtar:hover:after {
    opacity: 1;
}

.profile-avtar .change-image {
    position: absolute;
    font-size: var(--tiny-10px-medium-size);
    color: var(--neutral-white);
    position: relative;
    z-index: 2;
    display: none;
}

.profile-avtar:hover .change-image {
    display: block;
}

.group-inps .group-inp .label {
    display: flex;
    gap: 10px;
    align-items: center;
}

.group-inps .group-inp .label img {
    width: 12px;
    height: auto;
    opacity: 0;
}

.group-inps .group-inp .name1 {
    opacity: 0;
}

.input-footer{
    display: flex;
    justify-content: end;
    align-items: baseline;
    width: 100%;
    gap: 2px;
}

.group-inps .group-inp:hover .name1,
.title5:focus + .input-footer .name1,
.group-inps .group-inp:hover .label img {
    opacity: 1;
}

.edit-profilr .cta-parent {
    display: flex;
    align-items: center;
    gap: 45px;
}

.edit-profilr .cta-parent .cancel {
    color: var(--trustegrity);
    height: auto;
    cursor: pointer;
}

.edit-profilr .cta-parent .cta {
    color: var(--system-danger-300)
}

.edit-profilr .cta-parent .edit-profile-btn {
    color: #fff;
    padding: 7px 15px;
    min-width: 112px;
    width: auto;
}

.group-inps .group-inp input::placeholder {
    color: var(--black-400);
}

.frame-child-a.group-inps {
    display: grid !important;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.frame-child-a.group-inps .group-inp {
    width: 100%;
}

.inp-error {
    font-size: 9px;
    color: var(--system-danger-500-base);
    width: 95%;
}

.textarea-height .input-footer {
    margin-top: 60px;
}

@media (max-width:767px) {
    .edit-profilr .cta-parent {
        flex-wrap: wrap;
        gap: 10px;
        grid-gap: 10px;
        justify-content: space-between;
    }
    .frame-group-info .group-inp{
        flex: unset;
        width: 100%;
    }
    .frame-group-info .group-inp.inline_block {
        width: 46%;
        margin-right: auto;
    }
    .frame-child-a.group-inps {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .frame-parent-info .cta-parent{
        width: 100%;
    }
}