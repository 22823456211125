html {
    font-size: 1rem;
    font-family: 'sf-pro';
}

body {
    overflow-x: hidden;
}

.select-box-blue {
    background-color: $blue !important;
    border: 1px solid rgba(43, 117, 214, 0.2) !important;
    border-radius: 2px !important;
    
    &-round {
        background-color: $blue !important;
        border: 1px solid #C7D3E6 !important;
        border-radius: 3px;
        
        &:focus {
            background-color: white !important;
            border-color: $primary-dark !important;
        }
    }
}

.cursor {
    cursor: pointer;
}

.wrapper {
    background-color: #f9f9f9;
}

.select-box-bottom-line {
    background-color: #fff !important;
    border-bottom: 1px solid #C7D3E6 !important;
    border-radius: 0 !important;
}

aside, .ant-layout-sider {
    flex: 0 0 330px !important;
    max-width: 330px !important;
    min-width: 330px !important;
    width: 330px !important;    
}

.ant-layout-sider.ant-layout-sider-collapsed {
    flex: 0 0 54px !important;
    max-width: 54px !important;
    min-width: 54px !important;
    width: 54px !important;
}

.header-modal-mark {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: -111;
    background: rgba(0, 0, 0, 0.3);

    &.show {
        z-index: 99;
    }
}

h2 {
    font-size: 1.1rem;
}

.form-label {
    color: rgba($primary, 0.6);
    font-size: $small-size !important;
}

.form-select {
    height: 37px;

    &:focus {
        box-shadow: none;
    }
}

.navbar-list {
    flex-direction: row !important;
}

.full-height {
    height: calc(100vh - 150px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 50px;
    
    &-top {
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 50px;
        height: calc(100vh - 85px);
    }

    @media (min-width: 993px) {
        padding-bottom: 100px;
    }

}

.h-height {
    height: 100%;
}

// scrollbar
::-webkit-scrollbar {
    width: 4px;
}
  
::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #DBE2ED;
}

::-webkit-scrollbar-thumb {
background: #7CB0F4; 
border-radius: 7px;
}

.w-full {
    width: 100%;
}

.content-page {
    font-family: "sf-pro";
}

.primary_fill_btn {
    border-color: $bright-gray !important;
    background-color: $bright-gray !important;

    &:hover {
        background-color: $bright-gray !important;
    }

}

.primary_out_btn {
    border-color: $bright-gray !important;
    color: $bright-gray !important;
    background-color: transparent;
    font-size: .9rem;
    border-radius: 3px;
    font-weight: 300;

    &:hover {
        background-color: white !important;
        color: $bright-gray !important;
    }

    svg {
        fill: none !important;
    }
}

.second_fill_btn {
    border-color: $second-dark !important;
    background-color: $second-dark !important;

    &:hover {
        background-color: $second-dark !important;
    }

}

.second_out_btn {
    border-color: $second-dark !important;
    color: $second-dark !important;
    background-color: transparent;
    font-size: .9rem;
    border-radius: 3px;
    font-weight: 300;

    &:hover {
        background-color: white !important;
        color: $second-dark !important;
    }

    svg {
        fill: $second-dark !important;
    }
}

.card {
    width: 100%;
    border-radius: 3px;
    border: 1px solid #EAEAEA;

    &_over {
        &:hover {
            transition: all 0.3s ease-out 0s;
            box-shadow: 0px 4px 10px 0px rgba(6, 71, 101, 0.10);

            .head.out-bg {
                background-color: #F2F7FE;
                transition: all 0.3s ease-out 0s;
            }
        }
    }

    .head {
        border-radius: 3px 3px 0px 0px;
        background: #064765;
        width: 100%;
        padding: 15px;

        &.out-bg {

            background-color: transparent;
            .avatar-wrap + div .name {
                color: $second-dark;
            }

            .send {
                background-color: $second-dark;
                color: white;
                border: 1px solid var(--trustegrity);
            }
        
        }
        
        .avatar {
            width: 76px;
            height: 76px;
            border-radius: 100%;
        
            &-wrap {
                position: relative;

                & + div {
                    margin-left: 20px;
                    color: white;
                    text-align: left;

                    @media (max-width: 560px) {
                        margin-left: 10px;
                        align-items: flex-start !important;
                    }

                    .name {
                        color: #FFF;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        opacity: 1;
                    }

                    .label {
                        color: #595858;
                        font-size: .9rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;

                        &.inverted {
                            color: #e6e6e6;
                        }
                    }
                }
            }
        }

        .ellipse {
            width: 13.818px;
            height: 13.818px;
            position: absolute;
            right: 6.91px;
        }

        .send {
            width: 140px;
            height: 31px;
            justify-content: center;
            align-items: center;
            border-radius: var(--br-11xs);
            border: 1px solid var(--white);
            background-color: var(--white);
            box-sizing: border-box;
            color: var(--trustegrity);
            font-size: 0.8rem;
            font-style: normal;
            font-weight: 600;
            line-height:  1.3rem;
            gap: var(--gap-3xs);
            display: flex;
        }

        .social {
            white-space: nowrap;
            img, svg {
                margin: 10px;
                cursor: pointer;
            }
        }

        @media (max-width: 768px) {
            gap: 15px;
            .info{
                flex-direction: row !important;
            }
            .referral{
                align-self: center;
            }
        }
    }

    .content {
        padding: 10px 15px;
        border-top: 1px solid #F5F5F5;
        border-bottom: 1px solid #F5F5F5;
        background: #FFF;
        height: 100%;
        text-align: left;

        .detail {
            & > div {
                span {
                    color: #39424E;
                    font-size: .8rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    margin-left: 10px;
                }
            }
        }

        .title {
            color: #064765;
            font-size: .8rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    .footer {
        border-radius: 0px 0px 3px 3px;
        background: #FAFAFB;
        padding: 15px;
        margin-top: auto;

        .schedule {
            span {
                color: #064765;
                font-size: .7rem;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
            }
        }

        .btn {
            height: 30px !important;
            font-size: .8rem;
            font-style: normal;
            font-weight: 590;
            line-height: 21px;
        }

        @media (max-width: 768px) {
            .wrap, .out-btn-primary {
                width: 100%;
            }
        }
    }

    .welcome {
        padding: 15px;
        flex: 1;

        span {
            color: #064765;
            font-size: .9rem;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
        }

        .wrap {
            @media (max-width: 768px) {
                width: 100%;

                .btn {
                    width: 100%;
                }
            }
        }
    }
}

.invite_btn, .apply_btn {
    min-width: 162px !important;
    height: 34px !important;
}

.group_finder {
    .ant-select-selection-placeholder {
        color: $primary;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 600;
    }

}

.ant-select-item {
    color: $bright-gray;
    font-size: 0.8rem !important;
    font-style: normal;
    font-weight: 500;
    border-radius: 3px 3px 0px 0px;
    border: 1px solid var(--black-200, #F5F5F5);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
}

.search-wrap {

    input {
        background-color: transparent !important;
        border: none;
        box-shadow: none !important;
        padding-left: 0;
        height: 41px !important;
        font-style: normal;
        font-weight: 400;
        font-size: .9rem;
        line-height: 20px;
        letter-spacing: 0.3px;
        color: #39424E;
    }
}

.export {
    cursor: pointer;
    color: #605F5F;
    font-size: .7rem;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
}

.sidebar-title {
    &::before {
        background-color: transparent !important;
        content: none !important;
    }

    .label {
        color: #39424E;
        font-size: .9rem;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        padding-left: 20px !important;

    }

    .accordion > ul li a {
        background: #F6FAFF !important;
    }
}

.hel-sidebar-menu .hel-menu > li {
    padding: 11px 5px 11px 20px !important;
}

.hel-sidebar-menu .hel-menu {
    .menu-list{
        padding: 11px 5px 11px 20px !important;
        gap: 10px;
        display: flex;
        flex-direction: column;
        .back-to-legacy{
            white-space: nowrap;
            color: var(--neutral-blue) !important;
            font-size: 0.8rem !important;
            font-style: normal !important;
            opacity: 1 !important;
            line-height: 120%;
            padding: 3px 0 !important;
            img{
                height: 12px;
                width: 12px;
            }
            span{
                font-weight: 500 !important;
            }
        }
        .send-ref{
            padding: 3px 0 !important;
            color: var(--neutral-blue) !important;
            font-size: .8rem !important;
            font-style: normal !important;
            font-weight: 600;
            opacity: 1 !important;
            line-height: 120%;
            white-space: nowrap;
        }
    }
}

.hel-sidebar #sidebar-scrollbar {
    width: 287px !important;
}

.sidebar-title .menu-pin {
    z-index: 1;
}

.sidebar-title .menu-pin {
    top: -20px !important;

}

.menu-pinned .menu-pin svg {
    rotate: 0deg;
}

.menu-pin svg {
    rotate: 180deg;
}

.sidebar-profile {
    padding: 20px;;
    justify-content: flex-start !important;
    background-color: $primary;
}

#sidebar-scrollbar .sidebar-profile .sidebar-profile-info .title {
    color: white !important;
    font-family: 'SF Pro Display', sans-serif !important;
}

.sidebar-profile .sidebar-profile-info .profile-link {
    color: white;
    font-family: 'SF Pro Display', sans-serif !important;
    font-size: 0.6rem !important;
}

.profile-user-avatar {
    margin-right: 20px !important;
}

.sidebar-main .hel-sidebar {
    transform: translateX(-265px) !important;
}

.hel-sidebar-menu .hel-menu li a span {
    color: #5D6F88 !important;
    font-size: .8rem !important;
    font-style: normal !important;
    font-weight: 700;
    opacity: 1 !important;

    &.accordion {
        font-size: 1rem !important;
        line-height: 1.5 !important;
        font-weight: 700;
        color: $bright-gray !important;
        width: calc(100% - 27px);
        height: initial !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

li.listOpen span.accordion {
    white-space: initial !important;
}

.unread-count {
    display: flex;
    background-color: var(--trustegrity);
    border-radius: 30px;
    height: 16px;
    width: auto;
    justify-content: center;
    align-items: center;
    font-size: 0.625rem;
    min-width: 25px;
}

.hel-sidebar-menu {
    .accordion{
        li {
            a {
                .unread-count {
                    span {
                        color: #fff !important;
                        font-size: 0.625rem !important;
                    }
                }
            }

        }
    }

}

.hel-sidebar-menu .hel-menu li ul {
    margin-left: 0 !important;
    border: 0 !important;
    padding: 0 !important;
}

.sidebar-profile-info {

    .spin {
        order: inherit !important;
    }
}

.hel-sidebar-menu {
    padding: 0 !important;
    height: calc(100% - 96px);
}

.hel-sidebar #sidebar-scrollbar {
    height: calc(100vh - 64px) !important;
}

.hel-sidebar-menu .hel-menu {
    width: 100%;
    margin-top: 0;
    max-height: 100% !important;
    height: calc(100vh - 145px);
    overflow-y: auto;
}

.listOpen svg {
    rotate: 180deg;
}

.accordion.listClose ul {
    display: none;
}
.accordion.listOpen ul {
    display: block;
}

.borde-gray-bottom {
    border-bottom: 1px solid #EAEAEA;
}

.btn-outline-primary:hover {
    background-color: $active-color-blue;
    border-color: $active-color-blue;
}

.btn-primary:hover {
    background-color: $active-color-blue;
    border-color: $active-color-blue;
}

.divider-label {
    h6 {
        background-image: url('../../images/line-bg.svg');
        background-repeat: repeat-x;
        background-position: center right;
    
        span {
            background-color: #fff;
        }
    }
}

.select-hidden {
    display: none;
    visibility: hidden;
    padding-right: 10px;
}

.select {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 1rem;
    max-width: 260px;
    font-size: .9rem;
    border: 1px solid rgba(43, 117, 214, 0.2) !important;
    border-radius: 2px !important;
    height: 42px;
    width: 100%;
    color: #777D74;
}

.select-styled {
    position: absolute; 
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 9px 12px;
    transition: all 0.2s ease-in;
    &:after {
        content:"";
        width: 0.8em;
        height: 0.8em;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        position: absolute;
        top: 16px;
        right: 15px;
    }

    &:active, &.active {
        background-color: #E5FFF0;
        &:after {
            transform: rotate(180deg);
            border-color: transparent transparent #fff transparent;
        }
    }
}

.select-options {
    display: none; 
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #E5FFF0;
    height: fit-content;
    overflow-y: scroll;
    max-height: 200px;

    li {
      margin: 0;
      padding: 6px 0;
      text-indent: 15px;
      border-top: 1px solid #E5FFF0;
      
      &:hover, 
      &.is-selected {
        color: #374948;
        background: #E5FFF0;
      }
      &[rel="hide"] {
        display: none;
      }
    }
}

.react-datepicker__year-wrapper {
    max-width: 210px !important;
}

.react-datepicker__navigation-icon {
    top: 10px !important;
}

.react-datepicker__input-container {
    input {
        border: none !important;
        width: 90px;
        color: #777D74;
        @media (max-width: 1200px) {
            width: 100%;
        }
    }
}

.imageDateInput {
    position: relative;
    background: #FFFFFF;
    border: 1px solid #C7D3E6;
    border-radius: 3px;
    height: 41px;
    padding: 7px;
    margin-bottom: 0 !important;

    &:focus-within {
        background-color: #E5FFF0 !important;
    }

    img {
        height: fit-content;
        pointer-events: none;
        position: absolute;
        right: 7px;
        cursor: pointer;
    }
}

.css-tsrali-control {
    height: 41px;
    background-color: transparent !important;
}

.css-lzfvzg-singleValue {
    background-color: transparent !important;
}

.toogle-btn-group {
    background: #EBF4FF;
    @include borderLine(1px, solid, rgba(0, 110, 255, 0.14));
    border-radius: 65px;
    height: 32px;
    z-index: 0;
    width: fit-content;
    position: relative;
    width: 100%;
    max-width: 200px;
    text-transform: uppercase;
    cursor: pointer;

    & > div {
        text-align: center;
        font-size: .8rem;
        width: 50%;
        z-index: 4;
        height: 100%;
        line-height: 30px;

        &.active {
            color: white;
        }

    }

    span {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 1;
        transition: 0.3s ease left;
        background: #085ED0;
        border-radius: 40px;
        width: 50%;

        &.active {
            left: 50%;
        }
    }

    .btn {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
        color: #777D74;
        height: 100%;
        font-size: .8rem;
        padding-left: 15px;
        padding-right: 15px;
        text-transform: uppercase;

        &.active {
            background: $active-color-blue !important;
            border-radius: 40px !important;
            color: #fff;
            &:focus, &:hover {
                color: #fff !important;
            }
        }

    }
}

.gray-bg {
    background-color: transparent !important;
    color: #777D74;
    font-size: .9rem;

    // @media (min-width: 990px) {
    //     margin-bottom: 0 !important;
    // }
}

.rangeslider__handle-label, .rangeslider-horizontal .rangeslider__handle:after {
    display: none !important;
}

.rangeslider__handle-label, .rangeslider__handle-tooltip {
    color: white;
}

.rangeslider__handle {
    box-shadow: none !important;
    z-index: 1;
}

.rangeslider {
    background-color: #EBECEC !important;
}

.rangeslider-horizontal {
    height: 4px !important;
    border-radius: initial !important;
}

.rangeslider-horizontal .rangeslider__handle {
    border-radius: initial !important;
    border: none !important;
    background-image: url('../../images/slider-mark.svg') !important;
    background-color: transparent !important;
    background-repeat: no-repeat !important;
    width: 11px !important;
    height: 17px !important;

    span {
        color: white;
    }
}

.rangeslider__label-item {
    font-size: .8rem !important;
    color: $label-color-green;
    
    &:last-child {
        left: 1.5px !important;

        &:before {
            left: 2px;
        }
    }

    &:before {
        content: ' ';
        width: 2px;
        height: 7px;
        background-color: #EBECEC;
        position: absolute;
        left: 6px;
        top: -10px;
    }
}

.rangeslider, .rangeslider .rangeslider__fill, .btn-check:focus + .btn, .btn:focus {
    box-shadow: none !important;
}

.rangeslider {
    margin: 15px 0 !important;
}

.modal-footer {
    border: none !important;
}

.modal-title {
    color: $active-color-blue;
}

.modal-dialog {
    @media (min-width: 577px) {
        max-width: 610px !important;
    }
}

.react-datepicker {
    min-width: 220px;
}

.react-datepicker-popper {
    z-index: 11 !important ;
}

.image-select {
    img {
        max-width: 22px !important;
    }
}

.ReactModal__Overlay {
    z-index: 1111 !important;
}

.car-detail-row {
    & > div, & > select {
        width: 100%;
        margin-right: 0px;

        
        @media (min-width: 768px) {
            width: 50%;
            padding-right: 10px;
            &:last-child {
                padding-left: 0;
            }
        }
        
        @media (min-width: 993px) {
            width: 25%;
            padding-right: 10px;
        }
    }
}

.full-width {
    @media (max-width: 992px) {
        width: 100% !important;
    }
}

.fuel-group {
    width: 100% !important;

    @media (min-width: 992px) {
        width: 50% !important;
    }
}

.customer-field .css-tsrali-control, .css-9x2jh-control {
    border: none !important;
    box-shadow: none !important;
}

.customer-field .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
}

.error-text {
    font-size: .7rem;
}

.drivable-group {
    & > div {
        width: 100%;

        &:last-child {
            width: 100%;
            .expires {
                width: 40%;
            }

            & > div {
                width: 40%;
            }
        }

        @media (min-width:1000px) {
            width: 50%;
    
            &:last-child {
                width: 100%;
            }
        }
        
        @media (min-width:1251px) {
            width: 25%;
            margin-bottom: 10px; 
    
            &:last-child {
                width: 50%;
            }
        }
    }
}

.overlay {
    backface-visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 20px;
    color: #FFF;
    transform: scale(0);
    transform-origin: 0 0;
    transition: transform 400ms ease, opacity 200ms linear 200ms;
    z-index: 111;

    &.is-open {
      transform: scale(1);
    }
}

.modal-backdrop {
    z-index: -1111;
    &.show {
        z-index: 110;
    }
}

.modal-hiding {
    animation: modal-slide-bottom-animation-out .5s ease;
    -webkit-animation: modal-slide-bottom-animation-out .5s ease;
    -moz-animation: modal-slide-bottom-animation-out .5s ease;
    -o-animation: modal-slide-bottom-animation-out .5s ease;
}

.modal-opacity { 
    animation: modal-opacity-animation .5s ease;
    -webkit-animation: modal-opacity-animation .5s ease;
    -moz-animation: modal-opacity-animation .5s ease;
    -o-animation: modal-opacity-animation .5s ease;
}

.table_anchor {
    color: #246DCB !important;
    font-weight: bold;
}

.anticon {
    vertical-align: 0.05em !important;
}

.ant-table-tbody tr {
    cursor: pointer;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 16px 12px !important;
    font-size: .9rem;
}

.custom-table-pos {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-right: calc(var(--bs-gutter-x) * 0.5);
    margin-left: calc(var(--bs-gutter-x) * 0.5);
    background-color: #fff;
    z-index: 2;
}

.pointer, .reload-btn label {
    cursor: pointer;
}

.spin_loader {
    position: absolute !important;
    top: 50%;
    left: 50%;
    z-index: 222222;
}

.custom-form { 
    &-wrap {
        position: relative;

        input:focus + .custom-form-label {
            transform: translate(-10px, -25px);
            font-size: .9em;
            text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff;
        }
    }

    &-label {
        display: inline-block;
        position:absolute;
        left: 12px;
        top: 10px;
        transition: all 150ms ease-in;
        pointer-events: none;

        &.field-active {
            transform: translate(-10px, -25px);
            font-size: .8em;
            text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff;
        }
    }
}

div.select-box-bottom-line {
    div {
        padding: 0 !important;
        margin: 0 !important;
    
        span {
            margin: 0 !important;
        }
    }

}
.customer-field .field-active {
    transform: translateY(-25px);
}

.imageDateInput input {
    width: 100% !important;
}

.insurance-detail .custom-form-label {
    left: 25px !important;
}

.indiana-scroll-container {
    overflow-y: hidden !important;
}

.action_bar {
    position: relative;
    flex: 0 0 25vw !important;
    max-width: 25vw !important;
    min-width: 25vw !important;
    width: 25vw !important;
    transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;

    &-collapsed {
        flex: 0 0 54px !important;
        max-width: 54px !important;
        min-width: 54px !important;
        width: 54px !important;
        background-color: #f1f4f9;
    }
}

.del-icon {
    color: rgba(0, 0, 0, 0.85);
}

.css-117b8wa-singleValue, .css-fj7p42-control {
    background-color: transparent !important;
}

.select-box-bottom-line .css-fj7p42-control {
    border-style: none !important;
    border-width: 1px !important;
}

.select-box-bottom-line .css-1wy0on6 {
    display: none !important;
}

.css-1nmuewe-control {
    border-width: 0 !important;
    border-style: none !important;
    box-shadow: none !important;
}

.ant-tooltip-inner {
    color: black !important;
}

.note-board {
    &::before {
        content: '';
        border-top: 1px solid rgba(0, 0, 0, 0.4);
        width: 75%;
        right: 50px;
        position: absolute;

        @media (max-width: 768px) {
            width: 60%;
        }
    }

    span {
        color: $label-color-green;
        font-size: .9rem;
        line-height: 18px;
    }
}

.ant-divider-horizontal {
    margin: 10px 0 !important;
}

.h-full {
    height: 100%;
}

.note-placeholder {
    height: 118px;
    overflow-y: auto;
}

.note-modal, .note-placeholder {

    .ant-modal-body {
        border-radius: 8px !important;
        box-shadow: 0px 2px 41px rgba(40, 41, 61, 0.23), 0px 4px 10px rgba(34, 34, 35, 0.35);
    }
    
    .ant-modal-content {
        border-radius: 8px !important;
    }

    .date {
        color: rgba(0, 0, 0, 0.6);
        font-size: .9rem;
    }

    table {
        width: 100%;

        tr {
            td {
                padding: 8px 0;
                font-size: .9rem;
                font-size: .9rem;
                line-height: 22px;
                font-weight: 400;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);

                &:first-child {
                    font-weight: 600;
                    color: #000000 !important;
                }
                &:nth-child(2) {
                    color: rgba(0, 0, 0, 0.7);
                }
                &:nth-child(3) {
                    color: rgba(93, 111, 136, 0.6);
                }
            }
        }
    }

    .alert-text {
        font-weight: 600;
        font-size: .9rem;
        line-height: 19px;
        color: rgba(0, 0, 0, 0.75);
    }
}

.radio_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #EBF4FF;
    width: 175px;
    height: 28px;
    border-radius: 9999px;

    input[type="radio"] {
        appearance: none;
        display: none;
    }
    
    label {
        font-size: .8rem;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #EBF4FF;;
        width: 87.5px;
        height: 28px;
        text-align: center;
        border-radius: 9999px;
        overflow: hidden;
        transition: linear 0.3s;
        color: #226BCB;
        margin: 0 !important;
        cursor: pointer;
    }
    
    input[type="radio"]:checked + label {
        background-color: #085ED0;;
        color: #ffffff;
        font-weight: 900;
        transition: 0.3s;
    }    
}

.dashboard-panel {
    background: #fff;
    box-shadow: 0px 6px 7px rgba(40, 41, 61, 0.03), 0px 5px 5px -3px rgba(96, 97, 112, 0.02);
}

input:disabled, textarea:disabled {
    background-color: white;
}

.disable_btn {
    opacity: 0.7;
}

/* hack for temporary */
.content-page {

    .col-12 {
        padding: 0 !important;
    }

    @media (max-width: 768px) {
        padding: 0 !important;
    }
}

section .menu-pinned #content-page {
    transform: initial !important;
    margin-left: 270px;
    width: calc(100% - 270px) !important;
    transition: all .3s ease-in-out !important;
}

.content-page-hide {
    section{
        .menu-pinned{
            #content-page{
                transform: initial !important;
                margin-left: 0;
                width: 100% !important;
                transition: all .3s ease-in-out !important;
            }
        }
    }
}

.sidebar-title .accordion > ul li a {
    background-color: #FAFAFB !important;
    padding: 3px 0 !important;
}

#content-page {
    transition: all .3s ease-in-out !important;
}

.hel-sidebar {
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.15));
    top: 64px;
}

.hel-sidebar-logo {
    padding-top: 0 !important;
}

.log-out, .log-in {
    margin-left: auto;
}

.log-out {
    img {
        transform: rotate(180deg);
    }
}

.alert_text {
    font-size: 20px;
    color: #39424E;
}

.field_disabled {
    border-bottom: none !important;
    
    input, textarea {
        border-bottom: none !important;
    }

    svg, .input-bottom {
        display: none;
    }

    textarea {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        resize: none;
        overflow: auto;
    }    
}

.pastreferral .profile-info {
    max-width: 100% !important;
}

.hover-gray {
    transition: 0.2ms all;
    &:hover {
        background-color: #eaeaea;
    }
}

.log-out_mobile {
    display: none;
}

.menu_wrap {
    height: 100%;
    overflow-y: auto;
}

@media(max-width: 767px) {
    .hel-sidebar .overlayed {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 20%;
        height: calc(100vh - 61px);
        background-color: transparent;
    }

    .log-out, .log-in {
        span {
            // display: none;
        }
    }

    .log-out_mobile {
        display: block;
        margin: 0 auto 0 0;
        padding: 20px;
        width: 100%;
        border-top: 1px solid #F5F5F5;
    }

    .sidebar-main .hel-sidebar {
        transform: translateX(-100%) !important;
    }

    body #root .hel-sidebar #sidebar-scrollbar {
        width: 80% !important;
    }

    section .menu-pinned #content-page {
        margin-left: 0 !important;
        width: 100% !important;
    }

    body #root .main-section .hel-sidebar {
        width: 100% !important;
    }
}


@keyframes modal-slide-bottom-animation-out {
    from {
      top: 0;
      opacity: 1;
    }
    to {
      top: 100%;
      opacity: 0;
    }
}

@-webkit-keyframes modal-slide-bottom-animation-out {
    from {
      top: 0;
      opacity: 1;
    }
    to {
      top: 100%;
      opacity: 0;
    }
}
@-moz-keyframes modal-slide-bottom-animation-out {
    from {
      top: 0;
      opacity: 1;
    }
    to {
      top: 100%;
      opacity: 0;
    }
}

@keyframes modal-opacity-animation {
    0% {
        opacity: 1;
    }
    
      100% {
        opacity: 0;
    }
}

@-webkit-keyframes modal-opacity-animation {
    0% {
        opacity: 1;
    }
    
      100% {
        opacity: 0;
    }
}

@-moz-keyframes modal-opacity-animation {
    0% {
        opacity: 1;
    }
    
      100% {
        opacity: 0;
    }
}

@-o-keyframes modal-opacity-animation {
    0% {
        opacity: 1;
    }
    
      100% {
        opacity: 0;
    }
}

@keyframes modal-slide-bottom-animation {
    from {
      top: 100%;
    }
    to {
      top: 50%;
    }
}

@-webkit-keyframes modal-slide-bottom-animation {
    from {
      top: 100%;
    }
    to {
      top: 50%;
    }
}

@-moz-keyframes modal-slide-bottom-animation {
    from {
      top: 100%;
    }
    to {
      top: 50%;
    }
}

@-o-keyframes modal-slide-bottom-animation {
    from {
      top: 100%;
    }
    to {
      top: 50%;
    }
}