.arrow-outward-fill0-wght400-gr-icon {
    position: relative;
    width: 12px;
    height: 12px;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .view-application {
    position: relative;
    line-height: 120%;
    font-weight: 500;
  }
  
  .arrow-outward-fill0-wght400-gr-parent,
  .component-775 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .arrow-outward-fill0-wght400-gr-parent {
    flex-direction: row;
    gap: var(--gap-7xs);
  }
  
  .component-775 {
    border-radius: var(--br-10xs) var(--br-10xs) 0 0;
    background-color: var(--white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--black-200);
    flex-direction: column;
    padding: var(--padding-3xs) 40px var(--padding-3xs) var(--padding-3xs);
  }
  
  .shuffle-fill0-wght400-grad0-op-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    gap: var(--gap-7xs);
  }
  
  .component-776 {
    background-color: var(--white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-right: 1px solid var(--black-200);
    border-bottom: 1px solid var(--black-200);
    border-left: 1px solid var(--black-200);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-3xs) var(--padding-sm) var(--padding-3xs) var(--padding-3xs);
  }
  
  .vector-icon9 {
    position: relative;
    width: 12px;
    height: 12px;
  }
  
  .vector-wrapper3 {
    width: 12px;
    height: 12px;
    flex-direction: row;
    padding: var(--padding-11xs) var(--padding-10xs);
    box-sizing: border-box;
  }
  
  .close-fill0-wght400-grad0-opsz-parent,
  .component-777,
  .vector-wrapper3 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .component-777 {
    background-color: var(--white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-right: 1px solid var(--black-200);
    border-bottom: 1px solid var(--black-200);
    border-left: 1px solid var(--black-200);
    flex-direction: column;
    padding: var(--padding-3xs);
  }
  
  .close-fill0-wght400-grad0-opsz-parent {
    height: 12px;
    flex-direction: row;
    gap: var(--gap-7xs);
  }
  
  .component-parent,
  .component-778 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .component-778 {
    border-radius: 0 0 var(--br-10xs) var(--br-10xs);
    background-color: var(--white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-right: 1px solid var(--black-200);
    border-bottom: 1px solid var(--black-200);
    border-left: 1px solid var(--black-200);
    padding: var(--padding-3xs) 26px var(--padding-3xs) var(--padding-3xs);
  }
  
  .component-parent {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    text-align: left;
    font-size: var(--tiny-10px-medium-size);
    color: var(--color-darkslategray-100);
    font-family: var(--small-12px-medium);
    right: 5%;
    background-color: #fff;
    z-index: 2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .show-all1 {
    position: relative;
    line-height: 100%;
    font-weight: 500;
    display: inline-block;
    width: 99px;
    flex-shrink: 0;
  }
  
  .component-759-icon {
    position: relative;
    width: 16px;
    height: 18px;
  }
  
  .show-all-parent {
    border-radius: var(--br-10xs) var(--br-10xs) 0 0;
    background-color: var(--white);
    border-bottom: 1px solid var(--black-300);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-3xs);
  }
  
  .active {
    position: relative;
    line-height: 120%;
    font-weight: 500;
    display: inline-block;
    width: 100%;
    flex-shrink: 0;
  }
  
  .component-753-icon {
    position: relative;
    width: 16px;
    height: 18px;
    object-fit: cover;
  }
  
  .active-parent {
    background-color: var(--white);
    border-bottom: 1px solid var(--black-300);
    flex-direction: row;
    align-items: center;
    padding: var(--padding-3xs);
  }
  
  .active-parent,
  .cancelled-parent,
  .frame-root {
    display: flex;
    justify-content: flex-start;
  }
  
  .cancelled-parent {
    border-radius: 0 0 var(--br-10xs) var(--br-10xs);
    background-color: var(--white);
    flex-direction: row;
    align-items: center;
    padding: var(--padding-3xs);
  }
  
  .frame-root {
    position: relative;
    box-shadow: 0-4px 10px rgba(0, 0, 0, 0.05), 0 4px 4px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    text-align: left;
    font-size: var(--font-size-sm);
    color: var(--color-darkslategray-100);
    font-family: var(--small-12px-medium);
  }

  .frame-child9 {
    position: relative;
    width: 9px;
    height: 18px;
    object-fit: contain;
  }
  
  .vector-wrapper4 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-3xs) 0 94px;
  }
  
  .my-herd-i {
    position: relative;
    letter-spacing: 0.3px;
    line-height: 140%;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
    width: 178px;
    flex-shrink: 0;
  }
  
  .frame-top,
  .my-herd-i-can-explain-this-mo-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .my-herd-i-can-explain-this-mo-wrapper {
    border-radius: var(--br-10xs);
    background-color: var(--white);
    padding: var(--padding-3xs);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    overflow: auto;
  }
  
  .frame-top {
    position: absolute;
    left: 60%;
    z-index: 9;
    top: 15%;
    max-width: 100%;
    max-height: 100%;
    text-align: left;
    font-size: var(--text-xs-medium-size);
    color: var(--black-500-gray);
    font-family: var(--font-sf-pro-display);
  }