.info-content {
    font-size: 22px;
    font-family: var(--text-xs-medium);
}

.add-new-user-hover {
    border-radius: var(--br-11xs);
    width: 89px;
    height: 34px;
    align-items: center;
    justify-content: space-between;
    padding: var(--padding-6xs) 15px;
    mix-blend-mode: normal;
}

.add-new-user-hover,
.info-content,
.frame-group-info {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
}

.edit_profile .ant-spin-nested-loading {
    width: 100%;
}

.frame-group-info {
    /* width: 1109px; */
    overflow-y: auto;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 10px;
    gap: var(--gap-8xs);
}

.col {
    width: 882px;
    flex-shrink: 0;
    padding: 0 0 var(--padding-xl);
    box-sizing: border-box;
    gap: var(--gap-3xs);
}

.col-inner {
    color: var(--color-darkslategray-100);
    font-family: var(--body-16px-bold);
}

.frame-container {
    border-radius: var(--br-10xs);
    background-color: var(--white);
    border: 1px solid var(--black-300);
    box-sizing: border-box;
    padding: var(--padding-xl);
}

.frame-container {
    width: 100%;
    justify-content: space-between !important;
}

.frame-container,
.frame-parent7 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.component-448-parent {
    flex-direction: row;
    align-items: center;
    gap: 22px;
    z-index: 0;
}

.component-448-parent,
.frame-div {
    display: flex;
    justify-content: flex-start;
}

.component-448-icon {
    position: relative;
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%;
}

.frame-div {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    gap: var(--gap-2xs);
}

.frame-parent1 {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-3xs);
    z-index: 0;
}

.frame-parent1,
.frame-parent5,
.member-since-wrapper {
    display: flex;
    justify-content: flex-start;
}

.frame-parent2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;
}

.frame-parent3 {
    width: 289px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-xl);
}

.frame-parent4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-2xs);
}

.profile-name-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.profile-name {
    color: var(--trustegrity);
}

.frame-item {
    position: relative;
    border-radius: 50%;
    background-color: var(--system-success-700);
    width: 10px;
    height: 10px;
}

.username {
    position: relative;
    font-size: var(--text-xs-medium-size);
    letter-spacing: 0.3px;
    line-height: 140%;
    font-family: var(--font-sf-pro-display);
    color: var(--helios-input-placeholder);
}

.trustegrity-by-high {
    position: relative;
    font-size: var(--font-size-sm);
    line-height: 120%;
    font-family: var(--small-12px-regular);
    color: var(--trustegrity);
}

.frame-parent5 {
    flex-direction: row;
    align-items: center;
    gap: 13px;
    font-size: var(--small-12px-medium-size);
    color: var(--white);
    font-family: var(--small-12px-medium);
}

.icons-parent {
    border-radius: var(--br-10xs);
    background-color: var(--primary-400);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px var(--padding-3xs);
    gap: 8px;
}

.icons,
.text {
    position: relative;
}

.icons {
    width: 12px;
    height: 12px;
}

.member-since-wrapper {
    flex-direction: column;
    align-items: flex-start;
    color: var(--helios-input-placeholder);
    font-family: var(--font-sf-pro-display);
}

.member-since {
    position: relative;
    letter-spacing: 0.3px;
    line-height: 140%;
}

.frame-parent6 {
    flex-direction: row;
    gap: 18px;
    z-index: 1;
}

.frame-parent6,
.iconsocialdefaulttwitter {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.icon-color-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-6xs) var(--padding-10xs);
}

.vector-icon {
    position: relative;
    width: 16px;
    height: 16px;
}

.vector-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-6xs) var(--padding-7xs-5);
}

.frame-parent10,
.vector-wrapper1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-11xs) var(--padding-12xs);
}

.frame-parent10 {
    border-radius: var(--br-10xs);
    background-color: var(--white);
    border: 1px solid var(--black-300);
    flex-direction: row;
    align-items: center;
    padding: var(--padding-6xs-5) var(--padding-3xs);
    gap: var(--gap-3xs);
}

.iconsocialdefaulttwitter {
    flex-direction: column;
    padding: 8px var(--padding-10xs);
}

.icon-color1 {
    position: relative;
    width: 10px;
    height: 8px;
}

.brand-mark-icon {
    position: absolute;
    margin: 0 !important;
    height: 8.77%;
    width: 3.4%;
    top: 85.09%;
    right: 69.61%;
    bottom: 6.14%;
    left: 26.99%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    z-index: 2;
}

.frame-parent7 {
    margin: 0 !important;
    /* position: absolute;
    top: 29px;
    left: 745px; */
    flex-direction: column;
    gap: var(--gap-3xs);
    z-index: 1;
    font-size: var(--text-xs-medium-size);
    color: var(--trustegrity);
    font-family: var(--small-12px-medium);
}

.frame-parent8 {
    border-radius: var(--br-10xs);
    background-color: var(--white);
    border: 1px solid var(--black-300);
    align-items: center;
    padding: var(--padding-6xs-5) var(--padding-base) var(--padding-6xs-5) var(--padding-3xs);
    gap: var(--gap-3xs);
}

.cta-wrapper,
.frame-parent8,
.frame-parent9,
.vector-frame {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.vector-container {
    width: 12px;
    height: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: var(--padding-10xs);
    box-sizing: border-box;
}

.cta-wrapper,
.vector-container {
    align-items: center;
}

/* .vector-icon1 {
    position: relative;
    width: 8px;
    height: 8px;
} */

.cta1 {
    line-height: 21px;
    font-weight: 500;
    cursor: pointer;
}

.cta,
.cta1 {
    position: relative;
}

.frame-parent9,
.vector-frame {
    align-items: flex-start;
    padding: var(--padding-11xs);
}

.frame-parent9 {
    border-radius: var(--br-10xs);
    background-color: var(--white);
    border: 1px solid var(--black-300);
    align-items: center;
    padding: var(--padding-6xs-5) 22px var(--padding-6xs-5) var(--padding-3xs);
    gap: var(--gap-3xs);
}

.my-why {
    flex-direction: row;
    gap: var(--gap-3xs);
    font-size: var(--body-16px-bold-size);
}

.my-why,
.my-info,
.frame-parent13 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.text-parent {
    border-radius: var(--br-10xs);
    border: 1px solid var(--black-300);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-xl);
    gap: var(--gap-xl);
}

.text-parent,
.text-wrapper {
    background-color: var(--white);
    display: flex;
}

.text-wrapper {
    border-radius: var(--br-10xs) var(--br-10xs) 0 0;
    border-top: 1px solid var(--black-300);
    border-right: 1px solid var(--black-300);
    border-left: 1px solid var(--black-300);
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    width: 100%;
}

.sed-ut-perspiciatis {
    position: relative;
    font-size: var(--text-xs-medium-size);
    letter-spacing: 0.3px;
    line-height: 130%;
    font-weight: 500;
    font-family: var(--font-sf-pro-display);
    color: var(--black-500-gray);
    display: flex;
    /* align-items: flex-end; */
    width: 270px;
    height: 264px;
    flex-shrink: 0;
    cursor: pointer;
}

.my-info {
    height: 100%;
    flex-direction: column;
}

.frame-parent13 {
    border-radius: 0 0 var(--br-10xs) var(--br-10xs);
    background-color: var(--white);
    border-right: 1px solid var(--black-300);
    border-bottom: 1px solid var(--black-300);
    border-left: 1px solid var(--black-300);
    flex-direction: row;
    padding: 0 var(--padding-xl) var(--padding-xl);
    gap: var(--gap-xl);
    font-size: var(--small-12px-medium-size);
    color: var(--neutral-blue);
    font-family: var(--font-sf-pro-display);
    height: 100%;
}

.frame-parent14,
.placeholder-parent4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
}

.frame-parent15 {
    gap: 3px;
}

.placeholder-parent {
    gap: var(--gap-9xs);
    font-size: var(--text-xs-medium-size);
    color: var(--color-darkslategray-100);
    font-family: var(--small-12px-regular);
}

.frame-parent15,
.placeholder-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.name {
    letter-spacing: 0.5px;
    line-height: 120%;
    text-transform: uppercase;
    opacity: 0.7;
}

.frame-inner,
.name,
.placeholder-profile {
    position: relative;
}

.frame-inner {
    width: 250px;
    height: 0;
}

.my-group {
    border-radius: var(--br-10xs) var(--br-10xs) 0 0;
    /* box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05); */
    border: 1px solid var(--black-300);
    font-size: 18px;
    width: 100%;

    .frame-parent25 {
        @media (max-width: 768px) {
            display: none;
        }
    }
}

.frame-group-info .my-info .name {
    font-size: var(--padding-3xs);
}

.col,
.my-group,
.frame-parent25 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.text-group {
    border-radius: var(--br-10xs) var(--br-10xs) 0 0;
    background-color: var(--white);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 13px var(--padding-xl);
    box-sizing: border-box;
    position: relative;
    gap: 2px;
}

.text3 {
    position: relative;
    line-height: 28px;
    z-index: 0;
}

.show-all {
    position: absolute;
    margin: 0 !important;
    top: 20px;
    left: 375px;
    font-size: var(--font-size-sm);
    line-height: 100%;
    font-weight: 500;
    font-family: var(--small-12px-medium);
    color: var(--helios-primary-blue);
    /* opacity: 0; */
    cursor: pointer;
    z-index: 1;
}

.frame-parent25 {
    border-radius: 0 var(--br-10xs) 0 0;
    font-size: var(--text-xs-medium-size);
    color: var(--color-darkslategray-100);
    font-family: var(--small-12px-medium);
}

.table-header-cell-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--tiny-10px-medium-size);
    color: var(--gray-500);
}

.table-header,
.table-header-cell,
.table-header-cell1,
.table-header-cell2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.table-header {
    gap: var(--gap-9xs);
}

.table-header-cell,
.table-header-cell1,
.table-header-cell2 {
    background-color: var(--helios-status-colors-light-blue);
    border-bottom: 1px solid var(--gray-200);
    box-sizing: border-box;
    width: 304px;
    height: 44px;
    padding: var(--padding-xs) var(--padding-5xl) var(--padding-xs) var(--padding-xl);
}

.table-header-cell1,
.table-header-cell2 {
    width: 199px;
}

.table-header-cell2 {
    width: 150px;
}

.table-header-cell3,
.table-header-cell4 {
    background-color: var(--helios-status-colors-light-blue);
    border-bottom: 1px solid var(--gray-200);
    box-sizing: border-box;
    height: 44px;
}

.table-header-cell3 {
    width: 160px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-xs) var(--padding-5xl);
}

.table-header-cell4 {
    width: 68px;
}

.text4 {
    position: relative;
    line-height: 18px;
}

.arrow-down-icon {
    position: relative;
    width: 16px;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
}

.table-cell-child {
    position: relative;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.text8 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
}

.table-cell1 {
    background-color: var(--white);
    border-bottom: 1px solid var(--gray-200);
    box-sizing: border-box;
    width: 199px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-base) var(--padding-5xl) var(--padding-base) var(--padding-xl);
    gap: var(--gap-xs);
}

.table-cell-item {
    position: relative;
    border-radius: 50%;
    background-color: #2ed477;
    width: 16px;
    height: 16px;
}

.text9 {
    position: relative;
    line-height: 20px;
}

.text10 {
    position: relative;
    line-height: 22%;
}

.pending {
    margin-block-start: 0;
    margin-block-end: 14px;
}

.present {
    margin: 0;
}

.avatar-group,
.table-cell3 {
    flex-direction: row;
    justify-content: flex-start;
}

.table-cell3 {
    background-color: var(--white);
    border-bottom: 1px solid var(--gray-200);
    box-sizing: border-box;
    width: 160px;
    height: 60px;
    display: flex;
    align-items: center;
    padding: var(--padding-sm) var(--padding-5xl);
    text-align: center;
    color: var(--trustegrity);
}

.avatar-group {
    display: none;
    align-items: flex-start;
}

.avatar-icon,
.avatar-icon1,
.avatar-icon5 {
    border-radius: var(--br-181xl);
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
}

.avatar-icon1 {
    margin-left: -4px;
}

.avatar-icon5 {
    display: none;
}

.avatar {
    position: relative;
    border-radius: var(--br-181xl);
    background-color: var(--black-300);
    border: 2px solid var(--white);
    box-sizing: border-box;
    width: 28px;
    height: 28px;
    margin-left: -4px;
}

.text11 {
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 12px);
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    width: 24px;
}

.table-cell4 {
    background-color: var(--white);
    border-bottom: 1px solid var(--gray-200);
    box-sizing: border-box;
    width: 68px;
    height: 60px;
    flex-direction: row;
    align-items: center;
    padding: var(--padding-base) var(--padding-5xl);
}

.dropdown,
.table-cell4 {
    display: flex;
    justify-content: flex-start;
}

.dropdown {
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
}

.more-vertical-icon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.table-cell {
    background-color: var(--white);
    border-bottom: 1px solid var(--gray-200);
    box-sizing: border-box;
    width: 304px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-base) var(--padding-5xl) var(--padding-base) var(--padding-xl);
    gap: var(--gap-xs);
}

.table-cell2 {
    background-color: var(--white);
    border-bottom: 1px solid var(--gray-200);
    box-sizing: border-box;
    width: 150px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-base) var(--padding-5xl) var(--padding-base) var(--padding-xl);
}


.component-197-profile,
.cta-parent {
    flex-direction: row;
    align-items: center;
}

.frame-parent-info .cta1 {
    color: var(--trustegrity);
}

.profile-inner-info {
    .frame-parent-info {
        display: flex;
        padding: 15px;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

.menu-pinned {
    .component-197-info {
        .frame-parent-info {
            margin-left: unset;
        }
    }
}

#frameParentInfo {
    transition: 0.3s ease-in-out;

    &.sticky {
        position: fixed;
        top: 61px;
        background: var(--color-whitesmoke-100);
        z-index: 99;
        width: 100%;
        max-width: 1109px;

        @media (max-width:767px) {
            margin: 0 auto !important;
            box-shadow: var(--color-gray-500) 0px 10px 10px -10px;
        }
    }
}

.component-197-info {
    .frame-parent-info {
        display: flex;
        padding: 15px;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        z-index: 99;
        max-width: 1109px;
        margin: 0 auto;
        transition: all .3s ease-in-out;
    }
}

.iconarrowsnormalleft-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-3xs);
    opacity: 0;
}

.iconarrowsnormalleft {
    position: relative;
    width: 18px;
    height: 18px;
}

.back-to-dashboard {
    position: relative;
    line-height: 24px;
}

.profile-overview {
    position: relative;
    font-size: var(--body-16px-bold-size);
    line-height: 28px;
    font-family: var(--small-12px-medium);
    color: var(--bs-body-color);
}

.cta-parent {
    display: none;
    justify-content: center;
    gap: var(--gap-base);
    font-size: var(--text-xs-medium-size);
    color: var(--system-danger-500-base);
    font-family: var(--small-12px-regular);
}

.cta {
    line-height: 18px;
    display: inline-block;
    width: 153px;
    flex-shrink: 0;
}

.component-195 {
    border-radius: var(--br-11xs);
    border: 1px solid var(--helios-input-placeholder);
    box-sizing: border-box;
    height: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-6xs) var(--padding-6xl);
    color: var(--helios-input-placeholder);
    font-family: var(--small-12px-medium);
}

.cancel {
    position: relative;
    width: 43px;
    height: 24px;
    font-size: var(--font-size-sm);
}

.cancel1 {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 20px;
    font-weight: 600;
}

.frame-wrapper5 {
    margin: 0 !important;
    position: absolute;
    top: 17px;
    left: 1066px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    cursor: pointer;
    z-index: 2;
    font-size: var(--text-xs-medium-size);
    font-family: var(--small-12px-medium);
}

.flex {
    display: flex;
}

.h-screen {
    min-height: 100vh;
    background-color: var(--color-whitesmoke-100);
}

.relative {
    position: relative;
}

.table-header-cells,
.table-cells {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    width: 100%;
    background-color: white;
}

.table-header-cells .table-header-cell,
.table-cells .table-cell {
    width: 100%;
}

.table-cells .table-cell.table-cell4 {
    justify-content: end;
}

.profile-info {
    width: 100%;
    max-width: 1109px;
    margin: auto;
    margin-top: initial;
}

@media (max-width:1024px) {
    .md-flex-wrap {
        flex-wrap: wrap;
        flex-direction: row;
    }

    .frame-group-info .col {
        height: auto;
        width: 100%;
    }

    .profile-info {
        width: 100%;
        padding: 0 15px;
    }

    .md-w-full {
        width: 100%;
    }

    .h-screen {
        height: auto;
    }

    .notification-dropdown {
        right: 60px;
    }

    .text-wrapper {
        justify-content: start;
        padding: 15px;
    }

    .frame-group-info {
        overflow: unset;
    }

    .gap-27 {
        gap: 10px;
    }

    .component-448-parent {
        align-items: self-start;
        gap: var(--br-6xs);
    }

    .component-448-icon {
        width: 70px;
        height: 70px;
    }

    .frame-container {
        .info-header {
            flex-direction: column !important;
            gap: 15px;
            justify-content: flex-start !important;
            align-items: flex-start !important;

            .find_btn {
                align-self: center;
            }
        }
    }
}

.frame-container {
    .info-header {

        .find_btn,
        a:active,
        a:hover {
            color: var(--white) !important;
        }
    }
}

@media (max-width:767px) {

    .profile-info {
        padding: 0 10px;
    }

    .utah-county-group-parent,
    .frame-parent28,
    .ga-catalyst-group-parent {
        width: 100%;
        justify-content: space-between;
    }

    .frame-parent3 {
        width: 100%;
    }

    .table-header-cells .table-header-cell,
    .table-cells .table-cell {
        padding: 8px;
    }

    .show-all {
        left: 200px;
    }

    .table-cells .table-cell .text10 {
        line-height: 1.2;
    }

    .table-cells .table-cell .pending {
        margin-bottom: 5px;
    }

    .notification-dropdown {
        right: 50px;
    }

    form.frame-group-info {
        width: 100%;
    }

    form.frame-group-info .addnewuser-btn-addnewuser {
        flex-wrap: wrap;
        padding: 15px;
    }

    .frame-group-info .addnewuser-btn-addnewuser .frame-component-n-a-m-e {
        gap: 15px;
    }

    .frame-group-info .component-749 {
        padding-left: 0;
    }

    .ant-spin-nested-loading {
        width: 100%;
    }
}

.portalPopupOverlay {
    display: flex;
    flex-direction: column;
    position: fixed;
    inset: 0;
}

.show-only-on-mobile {
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
}
.my-group-mobile {
    background: white;
    padding: 0 20px 20px;
    font-size: .8rem;

    strong {
        font-size: .9rem;
    }
    .row {
        margin-bottom: 10px;
    }
}

.sponser-label {
    color: var(--trustegrity);
    font-size: var(--body-16px-bold-size);
}

.sponsor-item {
    display: flex;
    font-size: 0.75rem;
    gap: 2px;
    align-items: center;

    .label{
        color: var(--color-darkslategray-100);
        font-weight: 500;
    }
    .value{
        font-weight: 300;
    }

    .copy-img{
        height: 14px;
        width: 14px;
        cursor: pointer;
        margin-left: 5px;
    }
}